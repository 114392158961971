<template>
  <div class="class-list">
    <!-- 头部统计 -->
    <Topshow ref="numTop"></Topshow>
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From />
      </div>
      <Table ref="tablechange" />
    </GlobalChunk>
  </div>
</template>

<script>
import Topshow from "./compnents/orderModel.vue";
import From from "./Form";
import Table from "./Table";
export default {
  name: "class-list",
  components: {
    From,
    Table,
    Topshow
  },
  data() {
    return {
      platAdres: null,
    };
  },
  created() {
  },
  methods: {
  
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.ad_cstyle {
  width: 100%;
  padding: 15px;
  min-height: 110px;
  background: #F5F6FA;
  border-radius: 14px;
  margin-bottom: 15px;
  margin-top: -20px;

  .adc_top {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .adc_line {
      width: 3px;
      height: 13px;
      background: #0981FF;
      border-radius: 2px;
      margin-right: 5px;
    }

    .xiugai {
      /deep/.el-button {
        text-decoration: underline;
        padding: 5px 0 5px 10px;
      }
    }

  }
}

.actionstyle {
  margin: 20px 0;
}

.enable-tip {
  color: #FF8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.displaghf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.im_actin {
  img {
    width: 15px;
    height: 15px;
  }
}

.enable_center {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.center_show {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 0 20px 20px;
}

.verify-title {
  color: #0981FF;
  line-height: 16px;
  font-size: 16px;
  padding-left: 10px;
  border-left: 3px solid;
  margin-bottom: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-50 {
  margin-right: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.font-w {
  font-weight: bold;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.flex-as {
  display: flex;
  align-items: flex-start;
}

.center_form {
  background-color: #fff;
  padding: 20px 10px 10px 10px;

  .cf_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .tf_one {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: rgb(51, 51, 51);
      margin-right: 20px;

      .line {
        width: 4px;
        height: 16px;
        background: #4966d2;
        margin-bottom: 0px;
        margin-right: 10px;
      }
    }
  }

}</style>