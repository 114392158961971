<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="radiobtn">
        <div style=" display: flex;align-items: center;">
          <div class="faultBtn">
            <div :class="auditStatus === '00' ? 'active' : 'none'" @click="btnClick('00')">
              待发货({{ allnumcount.waitSend || 0 }}个)
            </div>
            <div :class="auditStatus === '03' ? 'active' : 'none'" @click="btnClick('03')">
              待自动发货({{ allnumcount.waitPlatformBuild || 0 }}个)
            </div>
            <div :class="auditStatus === '01' ? 'active' : 'none'" @click="btnClick('01')">
              下单成功({{ allnumcount.successSend || 0 }}个)
            </div>
            <div :class="auditStatus === '02' ? 'active' : 'none'" @click="btnClick('02')">
              下单失败({{ allnumcount.failSend || 0 }}个)
            </div>
          </div>
          <div style="margin-left: 80px;">
            <el-checkbox v-model="isFinishSenderAddress">筛选未完善地址的门店</el-checkbox>
          </div>
        </div>
        <div>
          <el-button type="warning" size="small" @click="delClick()" v-if="storeIdsList.length > 0"
            :key="70">批量快递下单</el-button>
          <el-button type="info" size="small" v-else :key="80">批量快递下单</el-button>
        </div>
      </div>
    </div>
    <!-- 待下单 -->
    <GlobalTable key="13" v-if="auditStatus === '00' || auditStatus === '03'" ref="GlobalTable" v-loading="loading"
      :columns="receptiontableColumns" :data="seachDataList" :currentPage="page.pageNum" :isSelection="true"
      :isCheckbox="flagSection" @handleSelect="handleSelect" :total="page.total"
      @handleCurrentChange="handleCurrentChange">
      <el-table-column label="门店店长" slot="sotrename" align="center" width="110px">
        <template slot-scope="{ row }">
          <span v-if="row.storeMangerName">{{
              row.storeMangerName + "-" + row.storeMangerMobile
            }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="待发货旧机" slot="waitSendNum" align="center" width="100">

        <template slot-scope="{ row }">
          <span style="cursor:pointer;color: rgb(9, 129, 255);text-decoration: underline;" @click="shipMachine(row)">{{
              row.modelCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="超3天未发货" slot="exceedNum" align="center" width="100">

        <template slot-scope="{ row }">
          <span style="cursor:pointer;color: rgb(255, 104, 123);text-decoration: underline;"
            @click="shipMachine(row, 3)">{{ row.timeoutNoSendCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总价值（元）" slot="phnoePrice" align="center" width="95px">

        <template slot-scope="{ row }">
          <span>{{ row.modelCost || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店发货人" slot="storeSendGood" align="center">

        <template slot-scope="{ row }">
          <span v-if="row.senderName">{{
              row.senderName + "-" + row.senderMobile
            }}</span>
          <div @click="saveSaleoutpople(row)" style="cursor: pointer;color: #409EFF;font-size: 14px;">
            <u> {{ row.senderName ? "修改" : "添加" }}</u>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="门店发货地址" slot="storeSendAdrress" align="center">

        <template slot-scope="{ row }">
          <el-tooltip v-if="row.senderAddress" class="item" effect="dark" :content="row.senderAddress" placement="top">
            <div class="Remarks">
              {{ row.senderAddress }}{{ row.senderAddressDetail }}
            </div>
          </el-tooltip>
          <div @click="Modify(row)" style="cursor: pointer;color: #409EFF;font-size: 14px;">
            <u> {{ row.senderAddress ? "编辑" : "添加" }}</u>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="收货方" slot="merchantName" align="center">

        <template slot-scope="{ row }">
          <div>{{ row.merchantName || "--" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="是否开通线上物流" slot="isOpenLogistics" align="center">

        <template slot-scope="{ row }">
          <span v-if="!row.isOpenLogistics">未启用</span>
          <span v-if="row.isOpenLogistics && row.isEnableLogistics">已启用</span>
          <span v-if="row.isOpenLogistics && !row.isEnableLogistics">已启用（门店未启用）</span>
        </template>
      </el-table-column>
      <el-table-column label="是否开启平台呼叫快递" slot="openPlatformCallExpress" align="center">

        <template slot-scope="{ row }">
          <span>{{ row.openPlatformCallExpress ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="满足发货金额" slot="minCostSend" align="center">

        <template slot-scope="{ row }">
          <div>{{ row.minCostSend || 0 }}元</div>
        </template>
      </el-table-column>

      <el-table-column label="收货地址" slot="storegetAdrress" align="center">

        <template slot-scope="{ row }">
          <div v-if="row.receiverName">{{ row.receiverName + "-" + row.receiverMobile }}</div>
          <span v-else>--</span>
          <el-tooltip v-if="row.receiverAddress" class="item" effect="dark"
            :content="row.receiverAddress + row.receiverAddressDetail" placement="top">
            <div class="Remarks">
              {{ row.receiverAddress + row.receiverAddressDetail }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="备注" slot="remaker" align="center" width="110px">

        <template slot-scope="{ row }">
          <!--isEnableLogistics	是否给门店开启物流 true 是 false 否costIsFill 是否满足发货金额 true 能 false 否  openPlatformCallExpress是否开启平台叫快递 TRUE 是 FALSE 否 inSendIng是否正在发货 true 能 false 否-->
          <span style="color: #409EFF;" v-if="!row.inSendIng &&
              row.isOpenLogistics &&
              row.isEnableLogistics
              ">已满足发货条件</span>
          <div style="color: rgb(255, 104, 123)" v-if="!row.isOpenLogistics">
            未启用物流，不能发货
          </div>
          <div v-else>
            <div style="color: rgb(255, 104, 123)" v-if="!row.isEnableLogistics">
              未给门店商启用物流，不能发货
            </div>
            <div v-else>
              <div v-if="!row.openPlatformCallExpress">
                <span style="color: rgb(255, 104, 123)"></span>
              </div>
              <div v-else>
                <span style="color: rgb(255, 104, 123)" v-if="!row.costIsFill"></span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operation" fixed="right" width="100px"
        v-if="auditStatus == '00' || auditStatus === '03'" align="center">

        <template slot-scope="{ row }">
          <!--2.2.2.1临时版本  待发货状态-只要回收商启用物流且门店商户启用物流均可呼叫快递去掉是否开启平台呼叫快递判断、发货金额判断 -->
          <!--isEnableLogistics	是否给门店开启物流 true 是 false 否isOpenLogistics是否开通线上物流  costIsFill 是否满足发货金额 true 能 false 否  openPlatformCallExpress是否开启平台叫快递 TRUE 是 FALSE 否-->
          <div v-if="row.isOpenLogistics &&
              row.isEnableLogistics
              ">
            <!-- 是否正在发货 true 能 false 否 -->
            <el-button v-if="!row.inSendIng" size="mini" type="warning" round @click="delClick(row)">快递下单</el-button>
            <div v-else>
              <!-- 发货来源 01 小程序，02 后台 -->
              <div v-if="row.sendIngSource == '01'">
                {{ row.sendIngStaffType == "03" ? "店长" : "店员" }}-
                {{ row.sendIngStaffName }}正在操作发货
              </div>
              <div v-else>
                <!-- 操作人是否自己 true 是 false 否 -->
                <div v-if="row.managerIsOwn">
                  <el-button size="mini" type="danger" round @click="ExitDelivery(row)">退出发货</el-button>
                  <el-button size="mini" type="warning" round @click="continueSendgood(row)">继续发货</el-button>
                </div>
                <div v-else>
                  {{ row.managerAcc }} {{ row.managerName }} 正在操作发货
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <el-button size="mini" type="info" round>快递下单</el-button>
          </div>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 下单成功 -->
    <GlobalTable key="14" v-if="auditStatus === '01'" ref="GlobalTable" v-loading="loading"
      :columns="receptiontableColumns" :data="seachDataList" :currentPage="page.pageNum" :total="page.total"
      @handleCurrentChange="handleCurrentChange">
      <el-table-column label="包裹编号" slot="naNobao" align="center">

        <template slot-scope="{ row }">
          <span>{{ row.expressParcelNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="运单状态" slot="semdflagable" align="center">

        <template slot-scope="{ row }">
          <span>{{
              row.expressState == "2"
                ? "运输中"
                : row.expressState == "6"
                  ? "已签收"
                  : "已取消"
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="预约取件时间" slot="arriveTimeRegion" align="center">

        <template slot-scope="{ row }">
          <span>{{ row.arriveTimeRegion }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单时间" slot="expressCreateTime" align="center">

        <template slot-scope="{ row }">
          <span>{{ row.expressCreateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作人" slot="name" align="center">

        <template slot-scope="{ row }">
          <span>{{ row.managerName + "-" + row.managerAcc }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发货快递" slot="expressdelivery" align="center">

        <template slot-scope="{ row }">
          <span @click="logisticsorderHref(row.expressNo)"
            style="cursor:pointer;color: rgb(9, 129, 255);text-decoration: underline;">{{ row.expressNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="取件状态" slot="isflagable" align="center">

        <template slot-scope="{ row }">
          <span>{{
              row.pickState == "01"
                ? "等待取件"
                : row.pickState == "02"
                  ? "超时未取件"
                  : row.pickState == "03"
                    ? "取消取件"
                    : "已取件"
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收货方" slot="merchantName" align="center">

        <template slot-scope="{ row }">
          <div>{{ row.merchantName || "--" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="待发货旧机" slot="waitSendNum" align="center" width="100">

        <template slot-scope="{ row }">
          <span style="cursor:pointer;color: rgb(9, 129, 255);text-decoration: underline;" @click="shipMachine(row)">{{
              row.modelCount }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" slot="Deliveryphoto" label="发货拍照" align="center">

        <template slot-scope="{ row }">
          <div class="images-box" v-if="row.sendProof">
            <img style="width: 70px; height: 70px" @click="seeImg(row)" :src="row.sendProof" alt="" />
          </div>
          <div v-else>暂无图片</div>
        </template>
      </el-table-column>
      <el-table-column label="总价值（元）" slot="phnoePrice" align="center" width="95px">

        <template slot-scope="{ row }">
          <span>{{ row.modelCost || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店发货人" slot="storeSendGood" align="center">

        <template slot-scope="{ row }">
          <span v-if="row.senderName">{{
              row.senderName + "-" + row.senderMobile
            }}</span>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 下单失败 -->
    <GlobalTable key="15" v-if="auditStatus === '02'" ref="GlobalTable" v-loading="loading"
      :columns="receptiontableColumns" :data="seachDataList" :currentPage="page.pageNum" :total="page.total"
      @handleCurrentChange="handleCurrentChange">
      <el-table-column label="包裹编号" slot="naNobao" align="center">

        <template slot-scope="{ row }">
          <span>{{ row.expressParcelNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单时间" slot="expressCreateTime" align="center">

        <template slot-scope="{ row }">
          <span>{{ row.expressCreateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="失败原因" slot="expressBuildErrorMsg" align="center">

        <template slot-scope="{ row }">
          <span style="color: red;">{{ row.expressBuildErrorMsg }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作人" slot="name" align="center">

        <template slot-scope="{ row }">
          <span>{{ row.managerName + "-" + row.managerAcc }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收货方" slot="merchantName" align="center">

        <template slot-scope="{ row }">
          <div>{{ row.merchantName || "--" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="待发货旧机" slot="waitSendNum" align="center" width="100">

        <template slot-scope="{ row }">
          <span style="cursor:pointer;color: rgb(9, 129, 255);text-decoration: underline;" @click="shipMachine(row)">{{
              row.modelCount }}</span>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 待发货旧机 -->
    <el-dialog title="待发货旧机" :visible.sync="spDialogVisible" :close-on-click-modal="false" width="1200px"
      @closed="spClosed">
      <GlobalForm :init-data="machineData" :form-item-list="machineItemList" :inline="true" :labelWidth="60" round
        @handleConfirm="machinementConfirm" confirmBtnName="查询">
        <el-form-item label-width="70px" class="el_from" label="门店名称" slot="storeId" style="margin:0">
          <el-select v-model="storeId" clearable filterable size="small" id="select" placeholder="请选择或输入门店名称">
            <el-option v-for="item in storeList" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_from" label="超时查询" slot="timeoutquery" style="margin:0" label-width="70px">
          <el-select v-model="copyType" placeholder="请选择" clearable filterable @change="selecthandleChange">
            <el-option v-for="item in shanpsSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" slot="staffMobile" style="margin:0">
          <el-input clearable placeholder="请输入手机号" v-model="staffMobile">
          </el-input>
        </el-form-item>
        <el-form-item label="姓名" slot="staffName" style="margin:0">
          <el-input clearable placeholder="请输入姓名" v-model="staffName">
          </el-input>
        </el-form-item>
        <el-form-item class="el_from" label="订单状态" slot="state" style="margin:0" label-width="70px">
          <el-select v-model="oderState" placeholder="请选择" clearable filterable>
            <el-option v-for="item in stateSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </GlobalForm>
      <GlobalTable ref="GlobalTable" :pageSize="machinepage.pageSize" v-loading="mcloading"
        :columns="machinetableColumns" :data="machineDataList" :currentPage="machinepage.pageNum"
        :total="machinepage.total" @handleCurrentChange="machineCurrentChange">
        <el-table-column label="订单编号" slot="orderNo" align="center">

          <template slot-scope="{ row }">
            <span style="text-decoration: underline;color: rgb(9, 129, 255);;cursor: pointer;">{{ row.orderNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="机型" slot="phoneModel" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.phoneModel || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column slot="state" label="订单状态" align="center">

          <template slot-scope="{ row }">
            <span v-if="row.state === '00'">待确认</span>
            <span v-else-if="row.state === '01'" style="color:red">待绑码</span>
            <span v-else-if="row.state === '02'">待支付</span>
            <span v-else-if="row.state === '03'">已绑码</span>
            <span v-else-if="row.state === '04'">已收货</span>
            <span v-else-if="row.state === '05'">降价收货</span>
            <span v-else-if="row.state === '10'">已取消</span>
            <span v-else-if="row.state === '20'">已作废</span>
            <span v-else-if="row.state === '30'">已退回</span>
          </template>
        </el-table-column>
        <el-table-column label="门店" slot="storeName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.storeName || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下单人" slot="actionplople" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.staffName }}-{{ row.staffMobile }}</span>
            <div>
              <el-tag type="success" size="mini" effect="dark">{{ row.staffType == "03" ? "店长" : "店员" }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="用户成交价" slot="finalPrice" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.finalPrice || "--" }}元</span>
          </template>
        </el-table-column>
        <el-table-column label="下单人" slot="name" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.contactName + "-" + row.contactPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="成交天数" slot="afterAckDays" align="center" width="80">

          <template slot-scope="{ row }">
            <span>{{ row.afterAckDays }}天</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" v-if="auditStatus === '00'" align="center">

          <template slot-scope="{ row }">
            <el-button size="mini" type="primary" round @click="OffdeliveryClick(row)">线下发货</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="spDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 快递下单 -->
    <el-dialog title="快递下单" :visible.sync="finishDialogVisible" :close-on-click-modal="false" width="1000px"
      @closed="finishClosed">
      <EXPSTATE :state="2"></EXPSTATE>
      <div style="margin-bottom: 15px;display: flex;align-items: center;">
        <div class="tidaan"></div>
        <span style="color: #FF687B;margin-right: 10px;">{{
              allParcelCount
            }}个包裹正在批量发货中，如有包裹发货失败，请返回在待发货页面重新下单</span>
        <el-button icon="el-icon-refresh" size="mini" type="success" @click="Refreshstatus">刷新状态</el-button>
      </div>
      <GlobalTable ref="GlobalTable" v-loading="urloading" :columns="finishtableColumns" :data="finishDataList"
        :maxHeight="700" :isPagination="false">
        <el-table-column label="包裹编号" slot="storeName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.expressParcelNo || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="门店商户" slot="smStaffName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.companyName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发货门店" slot="smStoreName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.storeName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="包含旧机" slot="waitNum" align="center">

          <template slot-scope="{ row }">
            <span style="color: rgb(9, 129, 255);cursor: pointer;">{{
              row.modelCount
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发货价值（元）" slot="waitAmount" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.modelCost || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下单时间" slot="createTime" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.expressCreateTime || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发货状态" slot="sendState" align="center">

          <template slot-scope="{ row }">
            <span style="color:red" v-if="row.expressBuild == '02'">失败</span>
            <span v-else style="color: rgb(9, 129, 255)">{{
              row.expressBuild == "00"
                ? "未下单"
                : row.expressBuild == "01"
                  ? "成功"
                  : "失败"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" slot="remarke" align="center">

          <template slot-scope="{ row }">
            <span style="color:red" v-if="row.expressBuild == '02'">{{
              row.expressBuildErrorMsg
            }}</span>
            <span v-else style="color: rgb(9, 129, 255);cursor: pointer;" @click="logisticsorderHref(row.expressNo)">{{
              row.expressNo || "--" }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" plain @click="finishDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 快递发货 -->
    <el-dialog title="快递发货" :visible.sync="deliveryDialogVisible" :close-on-click-modal="false" width="1000px"
      @closed="devClosed">
      <EXPSTATE :state="1"></EXPSTATE>
      <div style="margin-bottom: 15px;">
        已拆分为
        <span style="color: #FF687B;">{{ allParcelCount || 0 }}</span>
        个包裹，仅满足发货条件包裹可进行快递下单
      </div>
      <GlobalTable ref="GlobalTable" v-loading="urloading" :columns="urtableColumns" :data="urDataList" :maxHeight="700"
        :isPagination="false">
        <el-table-column label="包裹编号" slot="expressParcelNo" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.expressParcelNo || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="门店商户" slot="smStaffName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.companyName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发货门店" slot="smStoreName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.storeName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="包含旧机" slot="waitNum" align="center">

          <template slot-scope="{ row }">
            <span style="color: rgb(9, 129, 255);cursor: pointer;">{{
              row.modelCount
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发货价值（元）" slot="waitAmount" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.modelCost || "--" }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer" style="display: flex;justify-content: space-between;">
        <div></div>
        <div style="display: flex;align-items: center;">
          <div @click="ExitDelivery" class="sendgstyle">
            <el-statistic :value-style="{
              'font-size': '14px',
              color: '#FF687B',
              width: '50px',
            }" ref="statistic" @finish="hilarity" format="mm:ss" v-model="deadline4" time-indices>
            </el-statistic>
            退出发货
          </div>
          <el-button size="small" type="primary" plain @click="deliveryDialogVisible = false">关 闭</el-button>
          <el-button size="small" type="primary" @click="sureSendorder">确认下单</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 禁用/启用分转提示 -->
    <el-dialog :title="enableTitle" :visible.sync="enableShow" :close-on-click-modal="false" width="400px"
      @closed="insideClosed">
      <div class="enable-tip">
        <div>
          {{
              insideType
                ? "说明：添加授权分转后，默认启用该分转授权，按照设置生效时间进行设置。"
                : "说明：禁用后，分转授权失效，该商家与授权回收商成交的订单默认发往回收商。"
            }}
        </div>
        <div class="tip-sure">
          {{
                insideType
                  ? "是否确认启用发货分转到平台？"
                  : "是否确认禁用发货分转到平台？"
              }}
        </div>
      </div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="insideClosed">取消</el-button>
        <el-button type="primary" :loading="enableButLoading" @click.native="insideSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 选择发货人 -->
    <el-dialog title="选择发货人" :visible.sync="sureExDialog" :close-on-click-modal="false" width="800px">
      <GlobalTable ref="GlobalTable" :columns="zdtableColumns" :data="zdDataList" :maxHeight="700" :isPagination="false"
        :isSelection="true" @handleSelect="sendgoodseSelect">
        <el-table-column label="下单人" slot="staffType" align="center">

          <template slot-scope="{ row }">
            <span style="margin-right: 10px;">{{
              row.staffName + "-" + row.staffMobile
            }}</span>
            <el-tag :type="row.staffType == '03' ? 'warning' : 'success'" size="mini" effect="dark">{{ row.staffType ==
              "03" ? "店长" : "店员" }}
            </el-tag>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="sureExDialog = false">关 闭</el-button>
        <el-button size="small" type="primary" @click.native="exportSure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 快递下单 填写快递信息-->
    <el-dialog title="快递下单" :visible.sync="sublicenseDialogVisible" :close-on-click-modal="false" width="800px"
      @closed="fenClosed">
      <el-form :model="formData" label-position="left" :rules="formRulesDialog" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <EXPSTATE :state="0"></EXPSTATE>
        <el-form-item label="选择旧机：" prop="expressCode" label-width="110px">
          已选择<span @click="selectOldPhone" style="color: #0981FF;text-decoration: underline;cursor: pointer;">{{ deliverGoods.modelCount }}台</span>
        </el-form-item>
        <el-form-item label="选择快递：" prop="expressCode" label-width="110px">
          <el-radio-group v-model="formData.expressCode">
            <el-radio v-for="item in expressList" :label="item.expressCode" :key="item.expressCode">{{ item.expressName
              }}(一个包裹最多{{
              item.maximum
            }}台)</el-radio>
          </el-radio-group>
        </el-form-item>

        <template>
          <div class="center_show">
            <div>发货旧机：{{ deliverGoods.modelCount }}台</div>
            <div>旧机价值：{{ deliverGoods.modelCost }}元</div>
          </div>
        </template>
        <el-form-item prop="effectiveTime" label="选择上门时间：" style="margin: 0" label-width="120px">
          <el-select style="margin-right: 20px;" key="kp" v-model="formData.effectiveTime" @change="visitTime"
            @clear="clearvt" size="mini" placeholder="请选择日期" clearable>
            <el-option v-for="item in dayData" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select v-model="catalog" size="mini" key="op" placeholder="请选择时间段" clearable>
            <el-option v-for="item in timeData" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="sublicenseDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="fenConfirmDialog('ruleForm')">分配包裹</el-button>
      </span>
    </el-dialog>
    <!--退出发货操作提示 -->
    <el-dialog title="操作提示" :visible.sync="delDialogVisible" :close-on-click-modal="false" width="400px">
      <div class="enable-tip">
        <div class="tip-sure">是否确认退出发货？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="delConfirmDialog">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 线下发货提示 -->
    <el-dialog title="线下发货提示" :visible.sync="turnonShow" :close-on-click-modal="false" width="450px"
      @closed="enableClosed">
      <div class="enable-tip">
        <div>
          说明：标记线下发货需向店员确认发货快递单号，并填写在下方。标记后，待发货不再统计该订单。
        </div>
        <div class="tip-sure">是否确认该订单已线下发货？</div>
      </div>
      <div class="command">
        <div style="width: 130px;">线下快递单号：</div>
        <el-input v-model="OfflineDelivery" placeholder="请输入线下发货的快递单号"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="turnonShow = false">取 消</el-button>
        <el-button type="primary" :loading="enableButLoading" @click.native="offlineSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 修改门店发货地址 -->
    <el-dialog title="修改门店发货地址" :visible.sync="actionDialogVisible" :close-on-click-modal="false" width="700px"
      @closed="cangeClosed">
      <el-form :model="adrressruleForm" :rules="adrressrules" ref="adrressrules" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="详细地址" prop="senderAddress">
          <el-input placeholder="请输入详细地址" v-model="adrressruleForm.senderAddress"></el-input>
        </el-form-item>
        <el-form-item label="门牌号" prop="senderAddressDetail">
          <el-input placeholder="请输入门牌号" v-model="adrressruleForm.senderAddressDetail"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('adrressrules')">取 消</el-button>
        <el-button size="small" type="primary" @click="submitForm('adrressrules')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <el-image-viewer v-if="showViewer" :on-close="() => {
              showViewer = false;
            }
              " :url-list="imgList" />
    <!-- 选择旧机 -->
    <el-dialog title="选择旧机" :visible.sync="oldphoneDialogVisible" :close-on-click-modal="false" width="1200px"
      @closed="oldClosed">
      <GlobalForm :init-data="oldphoneData"  :form-item-list="oldmachineList" :inline="true" :labelWidth="60" round
        @handleConfirm="oldMachineConfirm" confirmBtnName="查询">
        <el-form-item label="订单编号" slot="orderNo" style="margin:0" label-width="70px">
          <el-input clearable placeholder="请输入订单编号" v-model="orderNo">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号" slot="staffMobile" style="margin:0">
          <el-input clearable placeholder="请输入手机号" v-model="staffMobile">
          </el-input>
        </el-form-item>
        <el-form-item label="姓名" slot="staffName" style="margin:0">
          <el-input clearable placeholder="请输入姓名" v-model="staffName">
          </el-input>
        </el-form-item>
      </GlobalForm>
      <GlobalTable ref="GlobalTable" :maxHeight="500" v-loading="mcloading" :columns="oldphonetableColumns"
        :data="oldnosendDataList" @handleSelectionChange="handleSelectionChange" :isSelection="true" @handleCurrentChange="oldsendCurrentChange" :isPagination="false">
        <el-table-column label="订单编号" slot="orderNo" align="center">

          <template slot-scope="{ row }">
            <span @click="goOrderDetail(row)" style="text-decoration: underline;color: rgb(9, 129, 255);;cursor: pointer;">{{ row.orderNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="机型" slot="phoneModel" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.phoneModel || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column slot="state" label="订单状态" align="center">

          <template slot-scope="{ row }">
            <span v-if="row.state === '00'">待确认</span>
            <span v-else-if="row.state === '01'" style="color:red">待绑码</span>
            <span v-else-if="row.state === '02'">待支付</span>
            <span v-else-if="row.state === '03'">已绑码</span>
            <span v-else-if="row.state === '04'">已收货</span>
            <span v-else-if="row.state === '05'">降价收货</span>
            <span v-else-if="row.state === '10'">已取消</span>
            <span v-else-if="row.state === '20'">已作废</span>
            <span v-else-if="row.state === '30'">已退回</span>
          </template>
        </el-table-column>
        <el-table-column label="门店" slot="storeName" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.storeName || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下单人" slot="actionplople" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.staffName }}-{{ row.staffMobile }}</span>
            <div>
              <el-tag type="success" size="mini" effect="dark">{{ row.staffType == "03" ? "店长" : "店员" }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="用户成交价" slot="finalPrice" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.finalPrice || "--" }}元</span>
          </template>
        </el-table-column>
        <el-table-column label="下单人" slot="name" align="center">

          <template slot-scope="{ row }">
            <span>{{ row.contactName + "-" + row.contactPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="成交天数" slot="afterAckDays" align="center" width="80">

          <template slot-scope="{ row }">
            <span>{{ row.afterAckDays }}天</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="oldphoneDialogVisible = false">关 闭</el-button>
        <el-button size="small" type="primary" @click="submitoldphoneForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import EXPSTATE from "./compnents/expressState.vue";
export default {
  components: {
    EXPSTATE,
    ElImageViewer,
  },
  name: "Table",
  data() {
    return {
      isFinishSenderAddress: false,
      copyType: "", //超时查询
      shanpsSelectList: [
        {
          name: "成交超3天",
          id: 3,
        },
        {
          name: "成交超5天",
          id: 5,
        },
      ],
      stateSelectList: [
        {
          name: "待确认",
          id: "00",
        },
        {
          name: "待绑码",
          id: "01",
        },
        {
          name: "待支付",
          id: "02",
        },
        {
          name: "已绑码",
          id: "03",
        },
        {
          name: "已收货",
          id: "04",
        },
      ],
      oderState: "",
      showViewer: false,
      imgList: [],
      quitStoreId: "", //退出发货ID
      batchId: "",
      allParcelCount: 0, //包裹数统计
      deadline4: 0, //发货倒计时
      catalog: "",
      //上门日期
      dayData: [
        {
          label: "今天",
          value: "0",
        },
        {
          label: "明天",
          value: "1",
        },
        {
          label: "后天",
          value: "2",
        },
      ],
      //上门时间
      timeData: [],
      adrressruleForm: {
        storeId: null,
        senderAddress: "",
        senderAddressDetail: "",
      }, //修改门店发货地址数据
      adrressrules: {
        senderAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      }, //修改门店发货地址验证
      sureExDialog: false, //选择发货人
      sublicenseDialogVisible: false, //填写快递信息
      finishDialogVisible: false, //快递下单结果
      auditStatus: "00",
      OfflineDelivery: "",
      turnonShow: false,
      efftimeFlag: false, //是否禁用生效时间
      adreeId: "",
      adressList: [],
      storeList: [],
      storeId: "",
      staffMobile: "",
      staffName: "",
      enableButLoading: false,
      command: "",
      loading: false,
      enableTitle: "启用分转提示",
      enableShow: false,
      seachDataList: [],
      machineDataList: [],
      oldnosendDataList: [],
      slectedoldnosendData: [],
      urDataList: [],
      finishDataList: [],
      initData: null,
      machineData: null,
      oldphoneData: null,
      imgShowIndex: 0,
      problemDialogVisible: false,
      deliveryDialogVisible: false, //快递发货
      shipaddressDialogVisible: false,
      spDialogVisible: false,
      oldphoneDialogVisible: false,
      delDialogVisible: false,
      imgShow: false,
      DialogImgList: [],
      machineItemList: [
        { slotName: "storeId" },
        { slotName: "timeoutquery" },
        { slotName: "staffMobile" },
        { slotName: "staffName" },
        { slotName: "state" },
      ],
      orderNo:"",
      oldmachineList: [
        { slotName: "orderNo" },
        { slotName: "staffMobile" },
        { slotName: "staffName" },
      ],
      formItemList: [
        {
          key: "storeId",
          type: "selectFilterable",
          lableWidth: "70px",
          labelName: "门店名称",
          clear: true,
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "已分配",
            },
            {
              value: false,
              label: "未分配",
            },
          ],
        },
        {
          lableWidth: "60px",
          key: "staffPhone",
          type: "input",
          labelName: "手机号",
          placeholder: "输入手机号",
        },
        {
          lableWidth: "45px",
          key: "staffName",
          type: "input",
          labelName: "姓名",
          placeholder: "输入姓名",
        },
      ],
      formRules: {
        isEnable: [
          {
            required: true,
            trigger: "blur",
            message: "请选择问题类型",
          },
        ],
        remark: [
          {
            required: true,
            trigger: "blur",
            message: "请选择问题类型",
          },
        ],
      },
      formRulesDialog: {
        expressCode: [
          {
            required: true,
            trigger: "blur",
            message: "请选择快递",
          },
        ],
        effectiveTime: [
          {
            required: true,
            trigger: "blur",
            message: "请选择上门时间",
          },
        ],
      },
      formData: {
        addressId: "",
        command: "",
        expressCode: "",
        companyId: "",
        merchantName: "",
        contactName: "",
        contactPhone: "",
        effectiveTime: "",
        id: "",
        merchantId: "", //编辑时
        address: {},
      },
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      machinepage: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      oldsendParams:{
        merchantId:"",
        startTime: "",
        staffName: "",
        staffMobile: "",
        endTime: "",
        orderNo:"",
        sendType:"",
        orderNoList: [],
        "storeIds": []
      },
      orderNoList:[],
      machineparams: {
        companyId: "",
        adminId: "",
        pageType: "", //	下单状态【待下单：00(默认)，下单成功：01，下单失败：02，待平台下单：03】
        orderNo: "",
        pickState: "", //取件状态【等待取件：01，超时未取件：02，取消取件：03，已取件：04】（下单成功）
        expressState: "", //	运单状态【运输中：2，已签收：6，已取消：7】（下单成功）
        expressParcelNo: "", //包裹号
        startTime: "",
        merchantId: "",
        endTime: "",
        state: "",
        pageNum: 1,
        pageSize: 5,
        staffName: "",
        staffMobile: "",
        timeoutDay: "", //超时查询(3天：3,5天：5)
        storeId: "",
      },
      insideType: false,
      //待发货旧机
      machinetableColumns: [
        { slotName: "orderNo" },
        { slotName: "phoneModel" },
        { slotName: "state" },
        { slotName: "storeName" },
        { slotName: "actionplople" },
        { slotName: "phoneName" },
        { label: "旧机串号", prop: "imei" },
        { slotName: "finalPrice" },
        { label: "旧机价值(元)", prop: "modelCost" },
        { label: "成交时间", prop: "ackTime" },
        { slotName: "afterAckDays" },
        { slotName: "operation" },
      ],
      //待发货旧机
      oldphonetableColumns: [
        { slotName: "orderNo" },
        { slotName: "phoneModel" },
        { slotName: "state" },
        { slotName: "storeName" },
        { slotName: "actionplople" },
        { slotName: "phoneName" },
        { label: "旧机串号", prop: "imei" },
        { slotName: "finalPrice" },
        { label: "旧机价值(元)", prop: "modelCost" },
        { label: "成交时间", prop: "ackTime" },
        { slotName: "afterAckDays" },
        { slotName: "operation" },
      ],
      //快递发货
      urtableColumns: [
        { slotName: "expressParcelNo" },
        { slotName: "smStaffName" },
        { slotName: "smStoreName" },
        { slotName: "waitNum" },
        { slotName: "waitAmount" },
      ],
      //快递下单
      finishtableColumns: [
        { slotName: "storeName" },
        { slotName: "smStaffName" },
        { slotName: "smStoreName" },
        { slotName: "waitNum" },
        { slotName: "waitAmount" },
        { slotName: "createTime" },
        { slotName: "sendState" },
        { slotName: "remarke" },
      ],
      //待下单
      beorderedtableColumns: [
        { label: "门店商户", prop: "companyName", width: "100px" },
        { label: "询价门店", prop: "storeName", width: "110px" },
        { slotName: "sotrename" }, //门店店长
        { slotName: "waitSendNum" }, //代发货旧机
        { slotName: "exceedNum" }, //超3天未发货
        { slotName: "phnoePrice" }, //总价值（元
        { slotName: "storeSendGood" }, //门店发货人
        { slotName: "storeSendAdrress" }, //门店发货地址
        { slotName: "merchantName" }, //收货方名称
        { slotName: "isOpenLogistics" }, //开通物流
        { slotName: "openPlatformCallExpress" }, //是否开启平台叫快递
        { slotName: "minCostSend" }, //满足发货金额
        { slotName: "storegetAdrress" }, //收货地址
        { slotName: "remaker" }, //备注
        { slotName: "operation" },
      ],
      //下单成功
      successfultableColumns: [
        { slotName: "naNobao" }, //包裹编号
        { label: "门店商户", prop: "companyName", width: "100px" },
        { label: "询价门店", prop: "storeName", width: "110px" },
        { slotName: "waitSendNum" }, //代发货旧机
        { slotName: "name" }, //操作人
        { slotName: "expressCreateTime" }, //下单时间
        { slotName: "expressdelivery" }, //发货快递
        { slotName: "semdflagable" }, //运单状态
        { slotName: "storeSendGood" }, //门店发货人
        { slotName: "arriveTimeRegion" }, //预约取件时间
        { slotName: "Deliveryphoto" }, //发货拍照
        { slotName: "isflagable" }, //取件状态
        { slotName: "merchantName" }, //收货方
      ],
      //下单失败
      failtableColumns: [
        { slotName: "naNobao" }, //包裹编号
        { label: "门店商户", prop: "companyName", width: "100px" },
        { label: "询价门店", prop: "storeName", width: "110px" },
        { slotName: "sotrename" }, //门店店长
        { slotName: "waitSendNum" }, //代发货旧机
        { slotName: "name" }, //操作人
        { slotName: "expressCreateTime" }, //下单时间
        { slotName: "expressBuildErrorMsg" }, //失败原因
        { slotName: "merchantName" }, //收货方
      ],
      zdtableColumns: [
        { slotName: "staffType" },
        { label: "成交订单（单）", prop: "modelCount", width: "110px" },
        { label: "旧机价值（元）", prop: "modelCost" },
      ],
      zdDataList: [], //发货人列表
      commentId: "",
      actionDialogVisible: false, //修改门店发货地址
      addrId: "",
      platAdres: null,
      mcloading: false,
      urloading: false,
      storeIdsList: [],
      storeIdFlag: false,
      onlyList: [],
      allnumcount: {
        waitSend: "",
        successSend: "",
        waitPlatformBuild: "",
        failSend: "",
      },
      deliverGoods: {
        modelCount: 0,
        modelCost: 0,
      }, //填写发货信息
      expressList: null, //填写发货信息快递
      reorderData: [],
      //下单前操作
      paramData: {
        sendType: "",
        startTime: "",
        endTime: "",
        "orderNoList": [],
        storeIds: [],
        orderNo:""
      },
      receptiontableColumns: [],
      fenParams: {
        addressId: "",
        command: "",
        companyId: "",
        contactName: "",
        contactPhone: "",
        effectiveTime: "",
        id: "",
        merchantIds: [],
      },
    };
  },
  created() {
    // this.handleCurrentChange();
    this.receptiontableColumns = this.beorderedtableColumns;
    this.$store.commit("tagsView/SETATPY", "00");
    this.$store.commit("tagsView/SETFINISH",null);
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    isFinishSenderAddress:{
      handler(old,newa){
        this.$store.commit("tagsView/SETFINISH",this.isFinishSenderAddress);
        this.handleCurrentChange();
      }
    },
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    "formData.expressCode": {
      handler: function (news, old) {
        if (news) {
          this.formData.effectiveTime = "";
          this.timeData = [];
          this.catalog = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
     // 跳转至订单详情
     goOrderDetail(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: {type: "edit", id: row.orderNo},
      });
      window.open(routeData.href, "_blank");
    },
    selecthandleChange() { },
    //看图片
    seeImg(val) {
      console.log(val);
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(val.sendProof);
    },
    //选择上门时间
    visitTime(val) {
      console.log(val);
      if (val != "") {
        this.getSendStartTime(val);
      }
    },
    //清空上门时间
    clearvt() {
      this.catalog = "";
      this.timeData = [];
    },
    //选择上门时间段
    getSendStartTime(day) {
      _api
        .getSendStartTime({
          expressCode: this.formData.expressCode,
          day,
        })
        .then((res) => {
          if (res.code == 1) {
            this.catalog = "";
            this.timeData = res.data;
          }
        });
    },
    //获取发货人列表
    getSenderList(storeId, merchantId) {
      _api.getSenderList({
        storeId,
        merchantId,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
      }).then((res) => {
        if (res.code == 1) {
          this.zdDataList = res.data;
        }
      });
    },
    //跳转到物流订单
    logisticsorderHref(row) {
      console.log(row);
      if (row) {
        let routeData = this.$router.resolve({
          path: "/logisticsManage/order",
          query: { id: row },
        });
        window.open(routeData.href, "_blank");
      }
    },
    //发货倒计时结束
    hilarity() {
      console.log("好看的环境规划");
      this.deliveryDialogVisible = false;
      this.sublicenseDialogVisible = false;
      this.handleCurrentChange();
    },
    //控制是否能选中
    flagSection(row, index) {
      if (
        !row.inSendIng &&
        row.isOpenLogistics &&
        row.isEnableLogistics
      ) {
        return true;
      } else {
        return false;
      }
    },
    //修改门店发货地址
    Modify(row) {
      this.adrressruleForm.storeId = row.storeId;
      this.adrressruleForm.senderAddress = row.senderAddress;
      this.adrressruleForm.senderAddressDetail = row.senderAddressDetail;
      this.actionDialogVisible = true;
    },
    //保存门店发货地址
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.adrressruleForm);
          _api.saveSenderAddress(this.adrressruleForm).then((res) => {
            if (res.code == 1) {
              this.actionDialogVisible = false;
              this.$message({
                message: "保存成功！",
                type: "success",
              });
              this.handleCurrentChange();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.actionDialogVisible = false;
    },
    //选择发货人
    saveSaleoutpople(row) {
      this.sureExDialog = true;
      this.reorderData = [];
      this.getSenderList(row.storeId, row.merchantId);
    },
    //获取发货人设置列表
    getMasterStaffList() {
      _api.getMasterStaffList().then((res) => {
        if (res.code === 1) {
          console.log(res);
        }
      });
    },
    //确认修改
    exportSure() {
      if (this.reorderData.length > 1) {
        this.$message.error("发货人只能是一个！");
        return;
      }
      if (this.reorderData.length === 0) {
        this.$message.error("请选择发货人！");
        return;
      }
      const sendPople = {
        staffId: "",
        storeId: "",
      };
      sendPople.storeId = this.reorderData[0].storeId;
      sendPople.staffId = this.reorderData[0].staffId;
      _api.saveSenderpople(sendPople).then((res) => {
        this.$message({
          message: "修改成功！",
          type: "success",
        });
        this.reorderData = [];
        this.sureExDialog = false;
        this.handleCurrentChange();
      });
    },
    //状态切换
    btnClick(type) {
      this.auditStatus = type;
      //更新下单状态
      this.$store.commit("tagsView/SETATPY", type);
      this.page.pageNum = 1;
      // if (this.auditStatus == "01" || this.auditStatus == "02") {
      //   this.SeachParams.startTime = null;
      //   this.SeachParams.endTime = null;
      // }
    },
    //线下发货
    OffdeliveryClick(row) {
      this.turnonShow = true;
      this.OfforderNo = row.orderNo;
    },
    //退出发货
    ExitDelivery(row) {
      if (row) {
        this.quitStoreId = row.storeId;
      }
      this.delDialogVisible = true;
    },
    //线下发货关闭
    enableClosed() {
      this.OfflineDelivery = "";
    },
    //线下发货确认
    offlineSubmit() {
      console.log(this.OfflineDelivery);
      if (!this.OfflineDelivery) {
        this.$message.error("请输入线下快递单号");
        return;
      }
      _api
        .dptmBindExpress({
          expressNo: this.OfflineDelivery,
          orderNo: this.OfforderNo,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg || "操作成功");
            this.turnonShow = false;
            this.OfflineDelivery = "";
            this.machineCurrentChange();
            this.handleCurrentChange();
          }
        });
    },
    //待发货旧机
    shipMachine(row, num) {
      this.spDialogVisible = true;
      this.getStoreSelectList(row.companyId);
      this.machineparams.companyId = row.companyId;
      this.copyType = num;
      this.machineparams.timeoutDay = num || null;
      (this.machineparams.merchantId =
        row.merchantId || this.SeachParams.merchantId),
        // 订单号
        (this.machineparams.orderNo = this.SeachParams.orderNo || "");
      //取件状态【等待取件：01，超时未取件：02，取消取件：03，已取件：04】（下单成功）
      this.machineparams.pickState = this.SeachParams.pickState || "";
      //	运单状态【运输中：2，已签收：6，已取消：7】（下单成功）
      this.machineparams.expressState = this.SeachParams.expressState || "";
      this.machineparams.startTime = this.SeachParams.startTime || "";
      this.machineparams.endTime = this.SeachParams.endTime || "";
      //包裹号
      this.machineparams.expressParcelNo = row.expressParcelNo || "";
      if (this.auditStatus == "00" || this.auditStatus == "03") {
        this.machineparams.pickState = "";
        this.machineparams.adminId = "";
        this.machineparams.expressState = "";
      } else if (this.auditStatus == "01") {
        this.machineparams.orderNo = "";
      } else {
        this.machineparams.orderNo = "";
        this.machineparams.pickState = "";
        this.machineparams.expressState = "";
      }
      //门店查询
      this.storeId = row.storeId.toString();
      this.machineparams.storeId = this.storeId || "";
      this.machinepage.pageNum = 1;
      this.machineCurrentChange();
    },
    //查询平台商家地址
    getStoreSelectList(companyId) {
      _api.getStoreSelectList({ companyId }).then((res) => {
        this.storeList = res.data;
      });
    },
    //关闭选择修改地址
    cangeClosed() {
      this.adreeId = "";
      console.log(this.adreeId);
    },
    //快递发货确认
    sureSendorder() {
      _api.immediatelyBuild({ batchId: this.batchId }).then((res) => {
        if (res.code == 1) {
          this.finishDialogVisible = true;
          this.deliveryDialogVisible = false;
          this.sublicenseDialogVisible = false;
          this.getendBatchInfo(this.batchId);
        }
      });
    },
    //刷新状态
    Refreshstatus() {
      this.getendBatchInfo(this.batchId);
    },
    //快递发货后批次详情
    getendBatchInfo(batchId) {
      _api.getBatchInfo({ batchId }).then((res) => {
        this.allParcelCount = res.data.allParcelCount;
        //	批次id
        this.batchId = res.data.batchId;
        this.finishDataList = res.data.list;
      });
    },
    //关闭发货填写信息
    fenClosed() {
      this.deadline4 = 0;
      this.orderNoList=[];
      this.deliveryDialogVisible = false;
      this.handleCurrentChange();
    },
    //确认发货填写信息
    fenConfirmDialog(formName) {
      console.log(
        this.formData.expressCode,
        this.catalog,
        this.deliverGoods.storeIds
      );
      // this.deliverGoods
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.catalog == "") {
            this.$message.error("请选择时间段！");
            return;
          }
          //分配包裹(去发货)
          _api
            .sendpickParcel({
              "orderNoList": this.paramData.orderNoList,
              merchantId: this.paramData.merchantId,
              expressCode: this.formData.expressCode,
              startTime: this.SeachParams.startTime,
              endTime: this.SeachParams.endTime,
              sendStartTime: this.catalog,
              storeIds: this.deliverGoods.storeIds,
            })
            .then((res) => {
              if (res.code == 1) {
                this.deliveryDialogVisible = true;
                this.getBatchInfo(res.data);
              }
            });
        } else {
          return false;
        }
      });
    },
    //启用、禁用
    switchInsideBiz(row) {
      console.log(row);
      this.insideType = row.isEnable;
      this.enableTitle = row.isEnable ? "启用分转提示" : "禁用分转提示";
      this.addrId = row.id;
      // this.rowMId = row.effectiveTime;
      if (!this.insideType) {
        this.enableShow = true;
      } else {
        this.efftimeFlag = false;
        _api.getMiddleInfo({ middleId: row.id }).then((res) => {
          this.formData = res.data;
        });
      }
    },
    // 启用、禁用弹框关闭
    insideClosed() {
      this.enableShow = false;
      this.command = "";
      this.handleCurrentChange(this.page.pageNum);
    },
    insideSubmit() {
      console.log(this.insideType);
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
    },
    handleSelectionChange(e){
      console.log(e);
      this.slectedoldnosendData=e
    },
    // 代发货旧机表格数据
    oldsendCurrentChange() {
     console.log(1212);
    },
    // 代发货旧机表格数据
    machineCurrentChange(val) {
      if (val) {
        this.machinepage.pageNum = val;
      }
      this.machineparams.pageNum = this.machinepage.pageNum;
      this.machineparams.pageSize = this.machinepage.pageSize;
      this.machineparams.pageType = this.auditStatus;

      this.mcloading = true;
      _api.getOldphoneList(this.machineparams).then((res) => {
        if (res.code === 1) {
          this.machineDataList = res.data.records;
          this.machinepage.total = res.data.total;
          this.machinepage.pageNum = res.data.current;
          this.mcloading = false;
        }
      });
    },
    //代发货旧机
    oldMachineConfirm(data) {
      this.oldsendParams.orderNoList= this.orderNoList
      this.oldsendParams.storeIds= this.deliverGoods.storeIds
      this.oldsendParams.merchantId= this.paramData.merchantId
      this.oldsendParams.sendType =  this.paramData.sendType;
      this.oldsendParams.startTime = this.SeachParams.startTime || "";
      this.oldsendParams.endTime = this.SeachParams.endTime || "";
      this.oldsendParams.staffName = this.staffName;
      this.oldsendParams.staffMobile = this.staffMobile;
      this.oldsendParams.orderNo = this.orderNo;
      _api.pickParcelChooseOrderList(this.oldsendParams).then((res) => {
        if (res.code === 1) {
          this.oldnosendDataList = res.data;
         this.$nextTick(()=>{
          this.oldnosendDataList.forEach(row => {
            this.$refs.GlobalTable.$refs.tableGroup.toggleRowSelection(row);
          });
         })
          this.mcloading = false;
        }
      });
    },
    //代发货旧机
    machinementConfirm(data) {
      console.log(data);
      this.machineparams.staffName = this.staffName;
      this.machineparams.staffMobile = this.staffMobile;
      this.machineparams.storeId = this.storeId;
      this.machineparams.timeoutDay = this.copyType;
      this.machineparams.state = this.oderState;
      this.machineparams.pageNum = 1;
      this.machineparams.pageSize = 5;
      _api.getOldphoneList(this.machineparams).then((res) => {
        if (res.code === 1) {
          this.machineDataList = res.data.records;
          this.machinepage.total = res.data.total;
          this.machinepage.pageNum = res.data.current;
          this.mcloading = false;
        }
      });
    },
    // 表格数据
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        isFinishSenderAddress:this.isFinishSenderAddress?false:null,//接口周维维要求的传参
        isOpenCompanyLogistics:this.SeachParams.isOpenCompanyLogistics,
        companyId: this.SeachParams.companyId,
        storeId: this.SeachParams.storeId,
        pickState: this.SeachParams.pickState,
        expressState: this.SeachParams.expressState,
        orderNo: this.SeachParams.orderNo,
        expressNo: this.SeachParams.expressNo,
        adminId: this.SeachParams.adminId,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageType: this.auditStatus,
        merchantId: this.SeachParams.merchantId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      if (this.auditStatus == "00" || this.auditStatus == "03") {
        baseRequest.pickState = "";
        baseRequest.adminId = "";
        baseRequest.expressState = "";
        baseRequest.expressNo = "";
      } else if (this.auditStatus == "01") {
        // baseRequest.orderNo = ''
      } else {
        baseRequest.expressNo = "";
        baseRequest.pickState = "";
        baseRequest.expressState = "";
      }
      this.loading = true;
      _api.dptmgetList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.ipage.records;
          this.page.total = res.data.ipage.total;
          this.allnumcount = res.data;
          this.page.pageNum = res.data.ipage.current;
          this.storeIdsList = [];
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
      if (this.auditStatus == "00" || this.auditStatus == "03") {
        this.receptiontableColumns = this.beorderedtableColumns;
      } else if (this.auditStatus == "01") {
        //成功
        this.receptiontableColumns = this.successfultableColumns;
      } else {
        //失败
        this.receptiontableColumns = this.failtableColumns;
      }
    },
    //快递下单关闭
    finishClosed() {
      this.handleCurrentChange();
    },
    //快递发货关闭
    devClosed() {
      this.handleCurrentChange();
    },
    selectOldPhone(){
      this.orderNoList=this.deliverGoods.orderNoList
      this.oldMachineConfirm()
      this.oldphoneDialogVisible = true;
    },
    submitoldphoneForm(){
      if(this.slectedoldnosendData.length===0){
        return this.$message.error('请选择要发货旧机');
      }
      this.orderNoList=this.slectedoldnosendData.map(item=>item.orderNo)
      this.paramData.orderNoList=this.orderNoList
      this.getbeforeGrabShipView();
      this.oldphoneDialogVisible = false;
    },
     // 代发货旧机弹框关闭
     oldClosed() {
      this.oldphoneDialogVisible = false;
      this.oldphoneData = null;
      this.orderNo = "";
      this.staffMobile = "";
      this.staffName = "";
      this.oldnosendDataList=[]
    },
    // 代发货旧机弹框关闭
    spClosed() {
      this.spDialogVisible = false;
      this.machineData = null;
      this.storeId = "";
      this.oderState = "";
      this.staffMobile = "";
      this.staffPhone = "";
      this.staffName = "";
      this.machineparams = {
        companyId: "",
        adminId: "",
        pageType: "", //	下单状态【待下单：00(默认)，下单成功：01，下单失败：02，待平台下单：03】
        orderNo: "",
        pickState: "", //取件状态【等待取件：01，超时未取件：02，取消取件：03，已取件：04】（下单成功）
        expressState: "", //	运单状态【运输中：2，已签收：6，已取消：7】（下单成功）
        expressParcelNo: "", //包裹号
        startTime: "",
        merchantId: "",
        endTime: "",
        state: "",
        pageNum: 1,
        pageSize: 5,
        staffName: "",
        staffMobile: "",
        timeoutDay: "", //超时查询(3天：3,5天：5)
        storeId: "",
      };
    },
    //继续发货
    continueSendgood(row) {
      this.deliveryDialogVisible = true;
      this.getBatchInfo(row.batchId);
    },
    //快递下单 批次详情
    getBatchInfo(batchId) {
      _api.getBatchInfo({ batchId }).then((res) => {
        this.allParcelCount = res.data.allParcelCount;
        //	批次id
        this.batchId = res.data.batchId;
        this.urDataList = res.data.list;
        this.deadline4 = Date.now() + res.data.sendIngLastMillisecond;
      });
    },
    // 快递下单
    delClick(row) {
      this.formData.effectiveTime = "";
      this.catalog = "";
      this.timeData = [];
      this.paramData = {
        sendType: "",
        storeIds: [],
        orderNo:this.SeachParams.orderNo,
        "orderNoList": [],
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        merchantId: null, //收货方ID
      };
      if (row) {
        //单个下单
        this.paramData.merchantId = row.merchantId;
        this.paramData.sendType = "01";
        this.paramData.storeIds.push(row.storeId);
      } else {
        if (this.storeIdsList.length === 1) {
          this.paramData.merchantId = this.storeIdsList[0].merchantId;
          this.paramData.sendType = "01";
          this.paramData.storeIds.push(this.storeIdsList[0].storeId);
        } else {
          //批量下单去重判断是否都是同一收货方
          const uniqueArr = this.storeIdsList.filter(
            (item, index) =>
              this.storeIdsList.findIndex(
                (i) => i.merchantId === item.merchantId
              ) === index
          );
          console.log(uniqueArr);
          if (uniqueArr.length !== 1) {
            this.$message.error("请选择同一收货方订单才能批量发货！");
            return;
          } else {
            this.paramData.merchantId = this.storeIdsList[0].merchantId;
            this.paramData.sendType = "02";
            this.storeIdsList.forEach((item) => {
              this.paramData.storeIds.push(item.storeId);
            });
          }
        }
      }
      console.log(this.paramData);
      this.getbeforeGrabShipView();
      this.sublicenseDialogVisible = true;
    },
    //去发货前查询数据
    getbeforeGrabShipView() {
      _api.beforeGrabShipView(this.paramData).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.deliverGoods = res.data;
          this.paramData.orderNoList=res.data.orderNoList;
          this.expressList = res.data.expressList;
          this.expressList.forEach((item) => {
            if (item.isFirst) {
              this.formData.expressCode = item.expressCode;
            }
          });
        }
      });
    },

    //批量快递下单
    handleSelect(data) {
      console.log(data);
      this.storeIdsList = data;
    },
    //发货人修改
    sendgoodseSelect(data) {
      console.log(data);
      this.reorderData = data;
    },
    //确认退出发货
    delConfirmDialog() {
      if (this.quitStoreId) {
        //退出发货（通过门店id）
        _api.quitGrabShipStore({ storeId: this.quitStoreId }).then((res) => {
          if (res.code === 1) {
            this.$message.success("退出成功");
            this.delDialogVisible = false;
            this.deliveryDialogVisible = false;
            this.sublicenseDialogVisible = false;
            this.handleCurrentChange();
          }
        });
      } else {
        //退出发货（通过批次id）
        _api.quitGrabShipBatch({ batchId: this.batchId }).then((res) => {
          if (res.code === 1) {
            this.$message.success("退出成功");
            this.delDialogVisible = false;
            this.deliveryDialogVisible = false;
            this.sublicenseDialogVisible = false;
            this.handleCurrentChange();
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  background-color: #fff;

  .tidaan {
    margin-right: 3px;
    width: 6px;
    height: 6px;
    background: #ff687b;
    border-radius: 50%;
  }

  .sendgstyle {
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    width: 130px;
    height: 32px;
    line-height: 31px;
    font-size: 13px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff687b;
    background: #ffffff;
    border: 1px solid #ff687b;
    border-radius: 4px;

    /deep/.el-statistic {
      width: 50px !important;
    }
  }

  .button_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .radiobtn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .faultBtn {
      // min-width: 500px;
      height: 34px;
      background: #f9fbfd;
      border: 1px solid #c1d1ff;
      box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
      border-radius: 21px;
      display: flex;
      cursor: pointer;

      .active {
        text-align: center;
        color: white;
        // width: 50%;
        height: 14px;
        padding: 0 15px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        line-height: 32px;
        height: 32px;
        background: #0981ff;
        border-radius: 20px;
      }

      .none {
        text-align: center;
        color: #333;
        // width: 50%;
        padding: 0 15px;
        height: 14px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        line-height: 32px;
        height: 32px;
        border-radius: 20px;
      }
    }
  }

  .switcStle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    /deep/.el-button {
      text-decoration: underline;
    }
  }

  .ad_cstyle {
    width: 100%;
    padding: 15px;
    min-height: 110px;
    background: #f5f6fa;
    border-radius: 14px;
    margin-bottom: 15px;
    margin-top: -20px;

    .adc_top {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .adc_line {
        width: 3px;
        height: 13px;
        background: #0981ff;
        border-radius: 2px;
        margin-right: 5px;
      }

      .xiugai {
        /deep/.el-button {
          text-decoration: underline;
          padding: 5px 0 5px 10px;
        }
      }
    }
  }

  .displaghf {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .actionstyle {
    margin: 20px 0;
  }

  .im_actin {
    img {
      width: 15px;
      height: 15px;
    }
  }

  .center_show {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 0 20px 20px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .images-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .images-box>img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }

  .images-box>span {
    color: #0981ff;
    cursor: pointer;
  }

  .text-down {
    color: #0981ff;
    cursor: pointer;
    text-decoration: underline;
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .img-box {
    margin-bottom: 20px;

    // 图片
    .imgs-num {
      text-align: center;
      margin: 5px 0;
      font-weight: bold;
    }

    .img-tips-box {
      display: flex;
      height: 30px;
      width: max-content;
      border: 1px solid #0981ff;

      .img-tips {
        width: 80px;
        text-align: center;
        line-height: 30px;
        box-sizing: border-box;
        cursor: pointer;
      }

      .img-tips-default {
        background: #fff;
        color: #0981ff;
      }

      .img-tips-choose {
        background: #0981ff;
        color: #fff;
      }
    }

    // 列表
  }
}
</style>
