<template>
  <div class="GlobalFormDemo">
    <GlobalForm :init-data="initData" :form-item-list="formItemList" :inline="true" :labelWidth="60" round :needBtnLoading="true"
      @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label="收货方名称" slot="operationSelect" style="margin:0" label-width="90px">
        <el-select v-model="merchantId" placeholder="请选择商家" filterable clearable>
          <el-option v-for="item in shanpsSelectList" :key="item.merchantId" :label="item.merchantName"
            :value="item.merchantId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="所属商家" slot="merchId" style="margin: 0" label-width="75px">
        <el-select v-model="companyId" placeholder="请输入或选择所属商家" filterable clearable @change="handleChangeInquiry">
          <el-option v-for="item in merchSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="询价门店" slot="stockStoreId" style="margin: 0" label-width="75px">
        <el-select v-model="storeId" placeholder="请先选择所属商家" filterable clearable :disabled="isHasCompany">
          <el-option v-for="item in storeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" v-if="pageType == '01'" label="取件状态" slot="qustate" style="margin: 0"
        label-width="75px">
        <el-select v-model="pickState" size="mini" placeholder="请选择" clearable>
          <el-option v-for="item in qustaData" :key="item.label" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" v-if="pageType == '01'" label="运单状态" slot="senstate" style="margin: 0"
        label-width="75px">
        <el-select v-model="expressState" size="mini" placeholder="请选择" clearable>
          <el-option v-for="item in sendlogData" :key="item.label" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" v-if="pageType == '01' || pageType == '02'" label="操作人" slot="staffId"
        style="margin: 0" label-width="75px">
        <el-select v-model="adminId" placeholder="请输入或选择操作人" filterable clearable>
          <el-option v-for="item in actionAdminList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="订单号" slot="productCode" style="margin: 0" label-width="60px">
        <el-input v-model="orderNo" :clearable="true" size="mini" placeholder="请输入订单号"></el-input>
      </el-form-item>
      <el-form-item class="el_from" v-if="pageType == '01'" label="物流单号" slot="productCode" style="margin: 0"
        label-width="80px">
        <el-input v-model="expressNo" :clearable="true" size="mini" placeholder="请输入物流单号"></el-input>
      </el-form-item>
      <el-form-item class="el_from" v-if="pageType == '01' || pageType == '02'" label="下单时间" slot="xiaSelect"
        style="margin: 0" label-width="75px">
        <el-date-picker v-model="entryTime" type="daterange" start-placeholder="开始日期" range-separator="~"
          end-placeholder="结束日期" clearable :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="el_from" v-if="pageType !== '01' && pageType !== '02'" label="成交时间" slot="turnoverSelect"
        style="margin: 0" label-width="75px">
        <el-date-picker v-model="turnTime" type="daterange" start-placeholder="开始日期" range-separator="~"
          end-placeholder="结束日期" clearable :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="el_from" v-if="pageType !== '01' && pageType !== '02'" label="收货方是否开通物流" slot="Openlogistics"
        style="margin: 0" label-width="150px">
        <el-select :disabled="!(companyId||merchantId)" v-model="isOpenCompanyLogistics" placeholder="请选择" filterable clearable>
          <el-option v-for="item in OpenlogisticList" :key="item.id" :label="item.name"
            :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <template>
        <el-button icon="el-icon-download" size="small" type="success" :loading="exportLoading" @click="exportExcel">导出Excel</el-button>
      </template>
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
import moment from "moment";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      merchantId: "",
      isHasCompany: true,
      companyId: "",
      expressNo: "",
      entryTime: null, //下单时间
      turnTime: null, //成交时间
      storeId: "",
      pickState: "", //取件状态
      expressState: "", //运单状态
      orderNo: "",
      adminId: "", //操作人
      storeList: [],
      shanpsSelectList: [],
      qustaData: [
        {
          label: "等待取件",
          value: "01",
        },
        {
          label: "超时未取件",
          value: "02",
        },
        {
          label: "取消取件",
          value: "03",
        },
        {
          label: "已取件",
          value: "04",
        },
      ], //取件状态
      sendlogData: [
        {
          label: "运输中",
          value: "2",
        },
        {
          label: "已签收",
          value: "6",
        },
        {
          label: "已取消",
          value: "7",
        },
      ], //运单状态
      initData: null,
      tableColumns: [
        { label: "订单编号", prop: "orderNo" },
        { label: "机型名称", prop: "phoneName" },
        { slotName: "finalPrice" },
        { label: "交易时间", prop: "ackTime" },
        { slotName: "num" },
        { slotName: "content" },
        { slotName: "recycleType" },
        { slotName: "operation" },
      ],
      formItemList: [
        { slotName: "operationSelect" },
        { slotName: "merchId" },
        { slotName: "stockStoreId" },
        { slotName: "qustate" },
        { slotName: "senstate" },
        { slotName: "staffId" },
        { slotName: "productCode" },
        { slotName: "xiaSelect" },
        { slotName: "turnoverSelect" },
        { slotName: "Openlogistics" },
      ],
      isOpenCompanyLogistics:null,
      OpenlogisticList:[{
        id:false,
        name:"未开启",
      },{
        id:true,
        name:"已开启",
      }],
      merchSelectList: [], //所属商家
      startTime: "",
      endTime: "",
      actionAdminList: [], //操作人列表
      exportLoading: false,
    };
  },
  created() {
    console.log(23343235);
    let startTime = moment()
      .day(moment().day() - 30)
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推30天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.turnTime = [startTime, endTime];
    this.handleConfirm();
    this.getadminSelectList();
    // 所属商家下拉
    this.getSelectList();
    this.getmercate();
  },
  computed: {
    //下单状态
    pageType() {
      return this.$store.state.tagsView.pageType;
    },
    isFinishSenderAddress(){
      return this.$store.state.tagsView.isFinishSenderAddress;
    },
  },
  watch: {
    companyId(newVal) {
      if (newVal != "") {
        this.isHasCompany = false;
      } else {
        this.storeId = "";
        this.isHasCompany = true;
      }
    },
    pageType(newsd) {
      console.log(newsd);
      let startTimes = moment()
        .day(moment().day() - 30)
        .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推30天的时间
      let endTimes = moment().format("YYYY-MM-DD 23:59:59");
      if (newsd !== "01" && newsd !== "02") {
        this.turnTime = [startTimes, endTimes];
        this.entryTime = null
      } else {
        this.entryTime = [startTimes, endTimes];
        this.turnTime = null
      }
      this.handleConfirm();
    },
  },
  methods: {
    //获取发货方商家
    getmercate() {
      this.shanpsSelectList = [];
      _api.listDptmMerchantList({ pageNum: 1, pageSize: 999 }).then((res) => {
        if (res.code === 1) {
          this.shanpsSelectList = res.data;
        }
      });
    },
    //导出Excel
    exportExcel(e) {
      console.log(this.SeachParams);
      this.exportLoading = true;
      _api
        .expressageExcel({ pageType: this.pageType,isFinishSenderAddress:this.isFinishSenderAddress?false:null, ...this.SeachParams })
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "快递下单明细.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");
        }).finally(() => {
            this.exportLoading = false;
          });
    },
    handleConfirm(data,cd) {
      if (this.pageType == "01" || this.pageType == "02") {
        if (this.entryTime) {
          this.startTime = Number(moment(this.entryTime[0]).format("x"));
          this.endTime = Number(moment(this.entryTime[1]).format("x"));
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      } else {
        console.log(1, this.turnTime);
        if (this.turnTime) {
          this.startTime = Number(moment(this.turnTime[0]).format("x"));
          this.endTime = Number(moment(this.turnTime[1]).format("x"));
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      }

      if (this.pageType == "00" || this.pageType == "03") {
        this.pickState = "";
        this.adminId = "";
        this.expressState = "";
        this.expressNo = "";
      } else if (this.pageType == "01") {
        console.log(1);
      } else {
        this.expressNo = "";
        this.pickState = "";
        this.expressState = "";
      }
      const SeachParams = {
        isOpenCompanyLogistics:this.isOpenCompanyLogistics,
        companyId: this.companyId,
        storeId: this.storeId,
        pickState: this.pickState,
        expressState: this.expressState,
        orderNo: this.orderNo,
        expressNo: this.expressNo,
        merchantId: this.merchantId,
        adminId: this.adminId,
        startTime: this.startTime || null,
        endTime: this.endTime || null,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      };
      this.SeachParams = SeachParams;
      console.log("提交了form", SeachParams);
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    //选择所属商家
    handleChangeInquiry(val) {
      console.log(val);
      this.companyId = val;
      if (val != "") {
        this.storeId = "";
        this.getStoreSelectList();
      }else{
        this.isOpenCompanyLogistics=null
      }
    },
    // 获取门店列表
    getStoreSelectList() {
      _api.getStoreSelectList({ companyId: this.companyId }).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.storeList = res.data;
        }
      });
    },
    //操作人列表
    getadminSelectList() {
      _api.getadminSelectList().then((res) => {
        if (res.code === 1) {
          this.actionAdminList = res.data;
        }
      });
    },
    // 所属商家下拉
    getSelectList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.merchSelectList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .price_input {
    width: 195px;
    background-color: #ffffff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #e4ecfd;
    box-sizing: border-box;
    color: #666666;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 30px;

    >input {
      outline: 0;
      width: 70px;
      text-align: center;
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flexwarp {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }

  .flex-je {
    display: flex;
    justify-content: flex-end;
  }

  .label {
    white-space: nowrap;
    margin-right: 10px;
    width: 56px;
  }

  .images-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .images-box>span {
    color: #0981ff;
    cursor: pointer;
  }

  .text-down {
    color: #0981ff;
    cursor: pointer;
    text-decoration: underline;
  }

  .img-box {
    margin-bottom: 20px;

    // 图片
    .imgs-num {
      text-align: center;
      margin: 5px 0;
      font-weight: bold;
    }

    .img-tips-box {
      display: flex;
      height: 30px;
      width: max-content;
      border: 1px solid #0981ff;

      .img-tips {
        width: 80px;
        text-align: center;
        line-height: 30px;
        box-sizing: border-box;
        cursor: pointer;
      }

      .img-tips-default {
        background: #fff;
        color: #0981ff;
      }

      .img-tips-choose {
        background: #0981ff;
        color: #fff;
      }
    }

    // 列表
  }

  /deep/input::-webkit-inner-spin-button {
    display: none !important;
  }
}
</style>
