<template>
    <div class="header">
        <div class="h_top">
            <div :class="{nosection:true, yuan:state==0}">
                1
            </div>
            <div :class="{'dash_line':true,actiondash:state==0}"></div>
            <div :class="{nosection:true, yuan:state==1}">
                2
            </div>
            <div class="dash_line"></div>
            <div :class="{nosection:true, yuan:state==2}">
                3
            </div>
        </div>
        <div class="h_center">
            <p :style="{color:state==0 ?'#0981FF':''}">填写快递信息</p>
            <p :style="{color:state==1 ?'#0981FF':''}">分配包裹</p>
            <p :style="{color:state==2 ?'#0981FF':''}">确认下单</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'expressState',
    mixins: [],
    components: {},
    props: {
        state: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {}
}
</script>

<style scoped lang="scss">
.header {
    width: 400px;
    margin: auto;
    margin-bottom: 30px;

    .h_top {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;

        .dash_line {
            width: 140px;
            height: 1px;
            margin: 0 5px;
            border: 1px dashed #999999 ;
        }
        .actiondash{
            border: 1px dashed #0981FF ;
        }

        .nosection {
            width: 30px;
            height: 30px;
            text-align: center;
            background: #FFFFFF;
            border: 1px solid #A7A7A7;
            border-radius: 50%;
            font-size: 16px;
            font-family: FZLanTingHeiS-R-GB;
            font-weight: 400;
            color: #666666;
            line-height: 30px;
        }

        .yuan {
            background: #0981FF;
            border: none;
            color: #FFFFFF;
        }
    }

    .h_center {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >p:nth-of-type(1) {
            margin-left: -21px;
        }

        >p:nth-of-type(2) {
            margin-left: -15px;
        }

        >p:nth-of-type(3) {
            margin-right: -11px;
        }
    }
}
</style>
